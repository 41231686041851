import React, { useState, useEffect } from 'react';
import MessengerCustomerChat from 'react-messenger-customer-chat';


export default function Footer() {
    const [showButton, setShowButton] = useState(false);

    // useEffect(() => {

    //     var chatbox = document.getElementById('fb-customer-chat');
    //     chatbox.setAttribute("page_id", "787588197919059");
    //     chatbox.setAttribute("attribution", "biz_inbox");


    //     // Initialize Facebook SDK
    //     window.fbAsyncInit = function() {
    //       window.FB.init({
    //         xfbml: true,
    //         version: 'v18.0'
    //       });
    //     };

    //     // Load SDK asynchronously
    //     (function(d, s, id) {
    //       var js, fjs = d.getElementsByTagName(s)[0];
    //       if (d.getElementById(id)) return;
    //       js = d.createElement(s); js.id = id;
    //       js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
    //       fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'));
    //   }, []);

    // useEffect(() => {
    //     window.fbAsyncInit = function () {
    //         window.FB.init({
    //             appId:'474166926521348',
    //             xfbml: true,
    //             version: 'v12.0'
    //         });
    //     };

    //     (function (d, s, id) {
    //         var js, fjs = d.getElementsByTagName(s)[0];
    //         if (d.getElementById(id)) { return; }
    //         js = d.createElement(s); js.id = id;
    //         js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
    //         fjs.parentNode.insertBefore(js, fjs);
    //     }(document, 'script', 'facebook-jssdk'));
    // }, []);


    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 200) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div>
            < section >
                <div className="mian-footer-section position-relative">
                    {/* <div className="chat-bot">
                        <img src="/images/email.svg" alt="img" height="100%" width="100%" />
                        <span className="live-chat">Live Chat</span>
                    </div> */}
                    <div className="container" data-aos="fade-up">
                        <div className="row">
                            <div className="col-lg-3 fottor-data">
                                <h2>Our Guarantee</h2>
                                <p>We are dedicated to maintaining our position as a leading distributor, specializing in wheels, tires, and a wide array of accessories. Our commitment to providing top-tier service fuels our constant efforts to expand and diversify our selection, regularly integrating new and sought-after wheel brands into our inventory. If you don't find your favorite wheel line among our offerings, we invite you to connect with us via email.</p>
                            </div>
                            <div className="col-lg-3 fottor-data">
                                <h2>Working Hours</h2>
                                <h3 className='menus_heads'>Hours</h3>
                                <p className="">Mon - Fri - 8:00AM to 6:00PM</p>
                                <h3 className='menus_heads'>Weekends</h3>
                                <p className="m-0">Saturday - 9:00AM to 3:00PM</p>
                                <p className="m-0">Sunday - Closed</p>
                            </div>
                            <div className="col-lg-3 fottor-data">
                                <h2>Need Help?</h2>
                                <h3 className='menus_heads'>Address</h3>
                                <p className="">Reno, NV 89506 United States</p>
                                
                                <p className="">Arlington, TX 76017 United States</p>
                               
                                <p className="">Hatfield, PA 19440 United States</p>

                                <p className="">Whiteland, IN. 46184 United States</p>


                                <h3 className='menus_heads'>Contact</h3>
                                <ul className="fottor-list p-0">
                                    <li><a href='mailto:sales@extremeperformance.com'>sales@extremeperformance.com</a></li>
                                    <li><a href="tel:4808929433" >480-892-9433</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-3 fottor-data">
                                <h2>Quick Links</h2>
                                <ul className="fottor-list p-0">
                                    <li><a href="/blog">Blog</a></li>
                                    <li><a href="/about-us">About</a></li>
                                    <li><a href="/contact-us">Contact Us</a></li>
                                    <li><a href="/warranty">Warranty Policy</a></li>
                                    <li><a href="/terms-conditoins">Terms & Conditions</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            < section >
                <div className="main-copy-right">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <p className="copy-right">Copyright © 2023. Extreme Performance.</p>
                            </div>
                            <div className="col-lg-3 social-icons">
                                {/*<a href="#" target="_blank">
                                    <i className="fa fa-facebook-f"></i>
                                </a>*/}
                                <a href="https://www.instagram.com/extremewheels_/" target="_blank" title="insta"><span style={{ display: 'none' }}>insta</span>
                                    <i className="fa fa-instagram ms-2" title="instagram"></i>
                                </a>
                                {/*<a href="#" target="_blank">
                                    <i className="fa fa-twitter mx-2"></i>
                                </a>*/}
                                {/*<a href="#" target="_blank">
                                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M3.5657 11.2175C3.5657 10.2328 2.76735 9.43441 1.78308 9.43441C0.798808 9.43441 0 10.2328 0 11.2175C0 12.2022 0.798352 13.0006 1.78308 13.0006C2.7678 13.0006 3.5657 12.2022 3.5657 11.2175ZM8.61737 13.0001H6.09154C6.09154 9.63582 3.36429 6.90857 0 6.90857V4.38273C4.75913 4.38273 8.61737 8.24098 8.61737 13.0001ZM10.4 13.0001C10.4 7.25625 5.74385 2.60011 0 2.60011V0C7.1797 0 13.0001 5.82041 13.0001 13.0001H10.4Z"
                                            fill="#272727" />
                                    </svg>
                                </a>*/}
                                {/*<a href="#" target="_blank">
                                    <i className="fa fa-google-plus-g ms-2"></i>
                                </a>*/}

                            </div>

                             {/* <MessengerCustomerChat
                                pageId="787588197919059"
                                appId="474166926521348"
                            />  */}

                            {/* <div // */}
                                {/* className="fb-customerchat" */}
                                {/* attribution="setup_tool" */}
                                {/* page_id="787588197919059" */}
                            {/* /> */}


                            {/* <div id="fb-root"></div> */}

                            



                            {/* <div id="fb-customer-chat" class="fb-customerchat"> </div> */}
                            <div className="col-lg-6 payment_icons">
                                <img className="ms-1" src="/images/verif.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/mastro.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/visa.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/discover.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/amrican.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/paypel.svg" alt="img" height="100%" width="100%" />
                                <img className="ms-1" src="/images/sitelock.svg" alt="img" height="100%" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    title="Back to top"
                    className={`back-to-top ${showButton ? 'show' : ''}`}
                    onClick={scrollToTop}
                >

                </button>
            </section >
        </div>
    )
}
