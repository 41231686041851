import { React, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import TOPBAR from "../../components/TopBar";
import SEARCHBAR from "../../components/SeachBar";
import MENUBAR from "../../components/Menu";
import FOOTER from "../../components/Footer";




export default function TermsCondditon() {

    const currentLink = window.location.href;
    const url = new URL(currentLink);
    const updatedUrl = url.toString();

    const [isHidden, setIsHidden] = useState(false);
    const [cartItems, setCartItems] = useState("");
    const [showMenuSide, setShowMenuSide] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);
    }, []);

    useEffect(() => {
        const storedCartItems = localStorage.getItem('totalCartItems');
        if (storedCartItems) {
            const parsedCartItems = JSON.parse(storedCartItems);
            const numCartItems = Object.values(parsedCartItems);
            const sum = numCartItems.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
            setCartItems(sum);
        }
        setTimeout(() => {
            setIsHidden(true);
        }, 2000);

    }, []);


    const setValueMenuSideBar = (value) => {
        setShowMenuSide(value)
    }

    return (
        <>

            <Helmet>
                <title>Terms and Conditions</title>
                <meta name="description" content='We currently only ship to addresses within the lower 48 states' />
                <link rel="canonical" href={updatedUrl} />
            </Helmet>

            <TOPBAR isHidden={isHidden} />
            <SEARCHBAR setValueMenuSideBar={setValueMenuSideBar} cartItemsdata={cartItems} />
            <MENUBAR showMenuSide={showMenuSide} setValueMenuSideBar={setValueMenuSideBar} />

            <section>
                <div className="term-banner">
                    <div className="parformane-bar">
                        <div className="container">
                            <ul className="p-0 m-0 listing-parmofamne">
                                <li>
                                    <a href="/">Home /</a>
                                </li>
                                <li className="active-bread">
                                    <a href="">Terms &amp; Conditions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <h2 className="text-center txt-main-listing">Terms &amp; Conditions</h2>
                </div>
            </section>

            <section>
                <div className="mian-term-bar">
                    <div className="container position-relative">
                        <div className="siderr-bar">
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Shipping:
                                </h3>
                                <p>
                                    We currently only ship to addresses within the lower 48 states. If
                                    you need any product shipped outside the lower 48, please contact us
                                    for shipping cost prior to ordering. Additionally, any free shipping
                                    offer is also only valid in the lower 48 states. We do reserve the
                                    right to cancel any order if Extreme Performance determines that shipping
                                    to an address may have adverse issues. We do not ship to addresses
                                    where the customer cannot sign for the package(s) such as APO, FPO,
                                    and DPO addresses and P.O. Boxes. Shipping times can take up to 30
                                    days depending on where the item is shipping from and going to.
                                </p>
                                <p>
                                    Any package returned to sender for any reason is subject to a 30%
                                    restocking fee and any shipping charges incurred.
                                </p>
                                <p>
                                    <b>
                                        *** Free shipping is for Wheels and Tires ONLY in the lower 48 states. ***
                                    </b> <br></br>
                                    {/* <b>
                                        *** Shipping charge of $14 will be applicable on orders below $100. ***
                                    </b> */}
                                </p>
                            </div>

                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /><p className='points' style={{ marginLeft: '46px', marginTop: '-22px', lineHeight: '23px' }}> LTL Shipment Proper Receiving Guidelines:</p>
                                </h3>
                                <p>
                                    Inspect the freight for obvious signs of damage. This may include tears, scratches, holes, dented-in corners, or an overall disheveled appearance. Note all incidences on the delivery receipt.</p>
                                <p>Compare the number of handling units to the number listed on the bill-of-lading and the delivery receipt.</p>
                                <p>Verify seal/shrink-wrap is intact.</p>
                                <p>If the condition and the quantity of your freight is acceptable, sign the delivery receipt. A signed delivery receipt with no exceptions is defined as a “clear delivery.” This means there were no damage or shortage associated with this delivery.
                                </p>
                            </div>



                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /><p className='points' style={{ marginLeft: '46px', marginTop: '-22px', lineHeight: '23px' }}> What to do if the freight is damaged or short:</p>
                                </h3>
                                <p>  You are required to inspect the shipment upon delivery. Damages or shortages must be noted on the receiving documents at the time of delivery. The shipper and/or the carrier reserve the right to inspect claimed damages or shortages. Report any damage or shortage immediately and send photos of damages.</p>
                                <p>If you fail to notify us within <strong>10 business days</strong> in accordance with these requirements, you will not be entitled to reject the goods and will be deemed to have accepted the goods.  </p>

                                <p> If the freight has major <strong>damage</strong>  to the containers, cartons, or packaging and the product inside is obviously not salvageable,  <strong>refuse</strong> the delivery. Make sure all information is properly outlined on the BOL and take pictures.<strong>Immediately notify our customer service or sales representatives or email us</strong> . </p>

                                <p>If the shipment has  <strong>minor</strong>  damage and the product inside looks acceptable, go ahead and accept the shipment, but note the damage on the delivery receipt (DR) in the presence of the driver. If cartons or product packaging is torn or crushed, open the product while the driver is present to give him/her visibility. Notating packaging defects is not always acceptable from a claim’s perspective. This documentation assists in the OS&D process and creates a stronger case for reimbursement. If you fail to notate damage on the DR, it becomes harder to prove that the damage occurred while in the carrier’s possession. Pictures of damages will assist in the claims process. <strong>Immediately notify our customer service or sales representatives or email us</strong> .  </p>

                                <p> <strong>If damage to the product is found after opening cartons</strong>  (concealed damaged) While most concealed damage claims are denied by freight companies, there is a 100% chance of denial if reported after five (5) days. Take pictures of the damaged product if possible. Also, save the damaged product and packaging so the carrier can inspect and assess the damages. If these items are not saved, it makes it almost impossible to collect on a claim.<strong>Immediately notify our customer service or sales representatives or email us</strong> . </p>

                                <p> If the pallets have been<strong>shrink-wrapped and the shrink wrap/packaging has been tampered with</strong> , note on the delivery receipt “shrink-wrap not intact.” This will assist in the claims process in the event your shipment is received short. </p>

                                <p>  We suggest you place all damaged shipments received in a predetermined area. The claim process may require onsite inspections from the carrier and retention of the shipment until the claim is resolved.</p>

                                <p> <strong>Customers using a transportation company of their choice for deliveries will be responsible for filing claims against their carrier for shortages and damages. </strong> </p>

                            </div>


                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Product
                                    Availability:
                                </h3>
                                <p>
                                    We try very hard to keep products on our website accurate and up to
                                    date. As with most custom wheel websites, we offer many styles of
                                    wheels from many manufacturers. Some of these wheels we have in our
                                    inventory and most of them are available from the manufacturers
                                    inventory. Not all styles, colors, sizes, offsets or bolt patterns
                                    are available at all times. Our inventory as well as the
                                    manufacturers inventory are constantly changing. We do our best to
                                    remove discontinued styles and add the new ones so that we can keep
                                    our website up to date. We reserve the right to decline, suspend,
                                    cancel, reject or limit the quantities of any order placed due to
                                    the product being unavailable. We will notify you if the product(s)
                                    you ordered are unavailable Due to the Covid-19 Pandemic we are
                                    unable to cancel any orders until further notice. We appreciate your
                                    understanding in this matter.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Pricing
                                    Policies:
                                </h3>
                                <p>
                                    We do our best to keep prices on our website accurate and up to
                                    date. Since we are all human, sometimes typing errors happen. If you
                                    notice a price on our website that looks wrong or too good to be
                                    true (such as a $500.00 wheel listed for $5.00), it’s probably an
                                    error. We will correct the error as soon as it is brought to our
                                    attention. We reserve the right to decline, suspend, cancel, reject
                                    or limit the quantities of any order placed due to a pricing error.
                                    We will notify you if there is a pricing discrepancy of the
                                    product(s) you ordered. Your credit card will not be charged if we
                                    notice a pricing error on your order. We appreciate your
                                    understanding in this matter.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Online
                                    Orders:
                                </h3>
                                <p>
                                    {/* When you place an order through our website, all orders are done
                                    through Pay-Pal . */}
                                     All orders are shipped to Pay-Pal verified
                                    addresses. Please make sure we have your contact number and make
                                    sure the shipping address is correct. We may use your IP address to
                                    help diagnose problems with our server, and to administer our
                                    website. Your IP address is used to help identify you and your
                                    shopping cart and to gather demographic information.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Fraud
                                    Prevention:
                                </h3>
                                <p>
                                    We practice fraud prevention techniques which include verification
                                    of your payment account and address confirmation. An authorized
                                    adult with a photo ID must be present to provide their signature
                                    upon delivery of your order. All orders must be signed for upon
                                    delivery. No orders will be left at the door without a signature by
                                    an adult. We may also use third-parties to help us validate, analyze
                                    or access the risk of any order placed on our website. We reserve
                                    the right to decline or reject any order placed without giving any
                                    reason. Fraud attempts will be reported to the authorities and will
                                    be prosecuted to the fullest extent of the law. We appreciate your
                                    understanding in this matter.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> In-store
                                    Labor Policy:
                                </h3>
                                <p>
                                    Any and all cars and or parts that are dropped off at our facility
                                    for any type of work to be performed is to be paid in full prior to
                                    all cars and or parts being returned to owner. If for any reason at
                                    any time your car and or parts must leave our facility you agree to
                                    pay for any and all work performed prior. By paying any remaining
                                    balance owed you also agree that any and all work performed has been
                                    completed to your satisfaction. If any and all work has not been
                                    completed to your satisfaction you agree that your car and or all
                                    parts are to remain in our possession until any and all work has
                                    been completed to your satisfaction and any and all work has been
                                    paid for in full. By leaving your vehicle and or paying a deposit
                                    for any and all work to be performed you agree to these terms and
                                    conditions.
                                </p>
                            </div>
                            {/* <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Snap
                                    Financing:
                                </h3>
                                <p>
                                    If you need to make any changes to your order please contact us
                                    right away. For anything else call the <a href="#">Application Hotline: (877)273-2066.</a>
                                </p>
                            </div> */}
                        </div>

                        <div className="shipingg return_ploicy_title">
                            <h3 className='points'>Our Return Policy :-</h3>
                        </div>
                        <div className="siderr-bar">

                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Receipt of
                                    Damaged Items:
                                </h3>
                                <p>
                                    Please inspect all packages for damage upon delivery. If you receive
                                    a damaged item, make a note of the damage on the shippers receipt
                                    and keep a copy. Please notify us the same day so we can notify the
                                    shipping company, file a damage claim form and send you a
                                    replacement if necessary. Please retain all packing materials in
                                    case it is necessary for item(s) to be returned. Claims must be made
                                    within 48 hours of receipt.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Order
                                    Cancellation Policy:
                                </h3>
                                <p>
                                    Order cancellations must be submitted by phone the same day within
                                    15 minutes of the order being placed to avoid order cancellation
                                    fees. Orders cancelled after 5:00 Pacific Time, Monday through
                                    Friday on the date the order was placed are subject to a fee of 30%
                                    cancellation fee. If the product(s) have been ordered from our
                                    suppliers or have been shipped, your order is also subject to all
                                    freight and handling charges. A 30% restocking fee will be applied
                                    if the order was cancelled after your product(s) have been ordered
                                    from the manufacturer. Special orders, such as custom made, custom
                                    machined or custom painted wheels are not applicable for
                                    cancellation.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> 5-Day Return
                                    Policy:
                                </h3>
                                <p>
                                    All returned merchandise must be in new condition. All return orders
                                    freight and handling charges are paid by the purchaser of the
                                    product unless the wrong item was shipped. A 30% restocking or
                                    cancellation fee will be charged on qualified returns and buyer is
                                    responsible to pay any original shipping fees. Our 5 day return
                                    policy is not applicable to special orders, such as custom made,
                                    custom machined or custom painted wheels.
                                </p>
                                <p>
                                    Wheels must be fit checked on the vehicle before mounting tires on
                                    them. Mounted wheels are not returnable. Wheels that were shipped
                                    with tires mounted on them must have not been installed on the
                                    vehicle and driven on.
                                </p>
                                <p>
                                    All merchandise must be properly packaged for return shipping. We
                                    are not able to give credit on merchandise which has been damaged
                                    during return shipping due to improper packaging. It is the
                                    customer’s responsibility to make sure the wheels and any hardware
                                    or accessories are packaged correctly and protected from being
                                    damaged.
                                </p>
                                <p>
                                    Please contact us to get an RGA (Returned Goods Authorization)
                                    number, packaging and shipping instructions. Items returned without
                                    authorization may be refused and returned freight collect.
                                </p>
                            </div>
                            <div className="shipingg">
                                <h3 className='points'>
                                    <img className="me-3" src="images/check.svg" alt="img" height="100%" width="100%" /> Privacy Policy:
                                </h3>
                                <p>
                                    Third party vendors, including Google, use cookies to serve ads
                                    based on a user’s prior visits to your website.
                                </p>
                                <p>
                                    Google’s use of the DoubleClick cookie enables it and its partners
                                    to serve ads to your users based on their visit to your sites and/or
                                    other sites on the Internet.
                                </p>
                                <p>
                                    Users may opt out of the use of the DoubleClick cookie for
                                    interest-based advertising by visiting Ads Settings. (Alternatively,
                                    you can direct users to opt out of a third-party vendor’s use of
                                    cookies for interest based advertising by visiting aboutads.info.)
                                </p>
                                <p>
                                    If you have not opted out of third-party ad serving, the cookies of
                                    other third-party vendors or ad networks may also be used to serve
                                    ads on your site, and should be disclosed in your privacy policy as
                                    well in the following manner.
                                </p>
                                <p>
                                    Extreme Performance Terms &amp; Conditions is subject to change without
                                    notice.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <FOOTER />
        </>

    )
}
